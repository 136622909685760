// Home.js

import React from "react";
import "../assets/style/home.css";

const Home = () => {
  return (
    <div className="home-container">
      <iframe scrolling="no" src="https://www.decimatecnologia.com.br/bulhoes" title='livrochatbot' ></iframe>

    </div>
  );
};

export default Home;
