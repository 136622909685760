import React from "react";
import "../assets/style/escritorio.css";
import gb from "../assets/imgs/gb.svg";
import telefone from "../assets/imgs/telefone.svg";

const Escritorio = () => {
  return (
    <div className="escritorio-container">
      <div class="conteudo">
      <div class="img-gb">
          <img src={gb} className="gb-img" alt="Gabriel Bulhões"></img>
        </div>
        <div class="titulos-escritorio">
          <h2>GABRIEL BULHÕES</h2>
          <p>
            {" "}
           Advocacia Criminal e Investigação Defensiva.{" "}
          </p>
        </div>


        <div className="fale-conosco">
          <img src={telefone} className="telefone" alt="telefone"></img>

          <div className="numero">
            <p>(84) 3033-1881</p>

            <p>Fale conosco</p>
          </div>
        </div>
        <a href="https://gabrielbulhoes.com.br/" target="_blank" rel="noreferrer"><button className="main-btn">Acesse o site principal do escritório</button></a>
      </div>
    </div>
  );
};

export default Escritorio;
